import React from "react";
import "./FootNotes.css"

const SourcesFootnotes = (props) => {
    return (
        <div>
            <div className={"component inlineBlock"}>
                <div className={"title sectionTitle"}>
                    Sources & Contact
                </div>
                <div className={"footNotesBody"}>
                    <p className={"indexKeyTitle"}>
                        Sources: </p>
                    <div>
                        <ul className={"sourceList"}>
                            {props.children}
                        </ul>
                    </div>
                    <div className={"alignLeft"}>
                        <a href={props.pdf}
                           target={"_blank"}
                           rel={"noreferrer"}>
                            Download PDF with additional details
                        </a>
                    </div>
                    {props.etplCsv && (
                        <div className={"alignLeft"}>
                            <a href={props.etplCsv}
                               target={"_blank"}
                               rel={"noreferrer"}>
                                Download CSV with ETPL Data 2024
                            </a>
                        </div>
                    )
                    }
                    <p className={"indexKeyTitle"}>
                        Site Data last updated on: </p>
                    <div>
                        <ul className={"sourceList"}>
                            <li><b>November 21st, 2024</b></li>
                        </ul>
                    </div>
                    <p className={"indexKeyTitle"}>
                        Colorado IIJA/IRA Workforce Dashboard </p>
                    <div>
                        <ul className={"sourceList"}>
                            <li>
                                <a title={"Colorado IIJA/IRA Workforce Dashboard "}
                                   href={"https://luminancedata.com/iija/dashboard"}
                                   rel={"noreferrer"}
                                   target={"_blank"}>
                                   https://luminancedata.com/iija/dashboard
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p className={"indexKeyTitle"}>
                        Contact: </p>
                    <div>
                        <ul className={"sourceList"}>
                            <li><b>Help with issues or
                                questions</b>: <a href={"mailto:admin@luminancedata.com?subject=Colorado+Future+Jobs+Help"}
                                                  rel={"noreferrer"}
                                                  title={"Help with issues or questions"}
                                                  target={"_blank"}>admin@luminancedata.com</a></li>
                            <li>Data project managed by&nbsp;
                                <a href={"https://luminancedata.com/"}
                                   target={"_blank"}
                                   rel={"noreferrer"}>Luminance
                                </a>
                            </li>
                            <li>Contact the <a target={"_self"}
                                               href={"mailto:cfj@onlinebunker.com?subject=From%20the%20Colorado%20Future%20Jobs%20website"}>Web
                                Designer</a></li>
                        </ul>
                    </div>
                    <div className={"clearFloat"}></div>
                    {/*<img src={attainment_logo} alt={"attainment logo"}*/}
                    {/*     className={"attainment_logo"}*/}
                    {/*     onClick={() => window.open("https://www.attainmentnetwork.org/", "_blank")}*/}
                    {/*/>*/}
                    <p className={"indexKeyTitle"}>
                        This site was developed through a public-private partnership in the State of Colorado </p>

                </div>
            </div>
        </div>
    );
}

export default SourcesFootnotes
